/* Common Imports */

import { styled } from "@mui/system";

/* Component Imports */

import { Skeleton } from "@mui/material";

/* Styled Components */

const PropertyCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	width: source === "carousel" ? "auto" : "100%",
	height: "100%",
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source !== "carousel" ? "48%" : "auto",
	},
	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		width: source !== "carousel" ? "48%" : "auto",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 960 */
	[theme.breakpoints.up("md")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		width: source !== "carousel" ? "31.75%" : "auto",
	},
	/* 720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 2K breakpoint  2560 ~~4 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: source !== "carousel" ? "23.75%" : "auto",
	},
}));

const PropertyCard = styled("div", {
	shouldForwardProp: (prop) => prop !== "source" && prop !== "isEditor",
})<{ source: string; isEditor: boolean }>(({ theme, source, isEditor }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "16px",
	objectFit: "contain",
	width: "100%",
	flexShrink: 1,
	flexGrow: 1,
	height: "100%",
	// boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12)",
	scrollSnapAlign: "start",

	/* XXS breakpoint - <280 */
	[theme.breakpoints.up("xxs")]: {
		// width: "6.65rem",
		// width: "100%",
		// height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/* XS breakpoint - 280.05 */
	[theme.breakpoints.up("xs")]: {
		// width: "16rem",
		// height: source === "business_profile_listings" && isEditor ? "18rem" : "15.5rem",
	},
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source !== "carousel" ? "100%" : "9rem",
		// height: source === "business_profile_listings" && isEditor ? "14.25rem" : "12rem",
	},
	/* galaxy S8+ - 360 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: source !== "carousel" ? "100%" : "10.125rem",
		// height: source === "business_profile_listings" && isEditor ? "15.25rem" : "13rem",
	},
	/* iphone se - 375 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: source !== "carousel" ? "100%" : "10.5rem",
		// height: source === "business_profile_listings" && isEditor ? "15.25rem" : "13rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: source !== "carousel" ? "100%" : "11rem",
		// height: source === "business_profile_listings" && isEditor ? "15.5rem" : "13.25rem",
	},
	/* iphone XR - 414 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: source !== "carousel" ? "100%" : "11.65rem",
		// height: source === "business_profile_listings" && isEditor ? "15.75rem" : "14rem",
	},
	/* iphone 14 pro max - 430 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: source !== "carousel" ? "100%" : "12.1rem",
		// height: source === "business_profile_listings" && isEditor ? "16.25rem" : "14rem",
	},
	/* 457 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 176)]: {
		width: source !== "carousel" ? "100%" : "13rem",
		// height: source === "business_profile_listings" && isEditor ? "16.75rem" : "14.5rem",
	},
	/* 485 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 204)]: {
		width: source !== "carousel" ? "100%" : "13.75rem",
		// height: source === "business_profile_listings" && isEditor ? "17.25rem" : "15rem",
	},
	/* 512 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 231)]: {
		width: source !== "carousel" ? "100%" : "14.5rem",
		// height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/* 540 */
	[theme.breakpoints.up("xsPlus")]: {
		width: source !== "carousel" ? "100%" : "14.75rem",
		// height: source === "business_profile_listings" && isEditor ? "18rem" : "15.75rem",
	},
	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: source !== "carousel" ? "100%" : "10.125rem",
		// height: source === "business_profile_listings" && isEditor ? "15rem" : "13rem",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		width: source !== "carousel" ? "100%" : "15.25rem",
		// height: source === "business_profile_listings" && isEditor ? "18rem" : "15.75rem",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: source !== "carousel" ? "100%" : "10.65rem",
		// height: source === "business_profile_listings" && isEditor ? "15.75rem" : "13.5rem",
	},
	/* 684 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		width: source !== "carousel" ? "100%" : "11.5rem",
		// height: source === "business_profile_listings" && isEditor ? "16rem" : "13.75rem",
	},
	/* 726 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 126)]: {
		width: source !== "carousel" ? "100%" : "12.35rem",
		// height: source === "business_profile_listings" && isEditor ? "16.25rem" : "14rem",
	},
	/* ipad Mini - 768 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: source !== "carousel" ? "100%" : "13.25rem",
		// height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/* ipad Air - 820 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: source !== "carousel" ? "100%" : "14.25rem",
		// height: source === "business_profile_listings" && isEditor ? "19rem" : "16.75rem",
	},
	/* 860 */
	[theme.breakpoints.up("sm860")]: {
		width: source !== "carousel" ? "100%" : "15.25rem",
		// height: source === "business_profile_listings" && isEditor ? "20.25rem" : "18rem",
	},
	/* 910 */
	[theme.breakpoints.up("sm910")]: {
		width: source !== "carousel" ? "100%" : "16.25rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
	/* 960 */
	[theme.breakpoints.up("md")]: {
		width: source !== "carousel" ? "100%" : "16.5rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
	/* iPadPro - 1024 */
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: source !== "carousel" ? "100%" : "17.75rem",
		// height: source === "business_profile_listings" && isEditor ? "21.75rem" : "19.5rem",
	},
	/* 1107 */
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		width: source !== "carousel" ? "100%" : "19.5rem",
		// height: source === "business_profile_listings" && isEditor ? "22.25rem" : "20rem",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		width: source !== "carousel" ? "100%" : "15.65rem",
		// height: source === "business_profile_listings" && isEditor ? "20.25rem" : "18rem",
	},
	/* 720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: source !== "carousel" ? "100%" : "16.95rem",
		// height: source === "business_profile_listings" && isEditor ? "20.5rem" : "18.25rem",
	},
	/* 1080p 125% breakpoint */
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: source !== "carousel" ? "100%" : "17.95rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
	/* 1080p breakpoint */
	[theme.breakpoints.up("xl")]: {
		width: source !== "carousel" ? "100%" : "17.45rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
	/* 2K breakpoint  2560p */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: source !== "carousel" ? "100%" : "15.45rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
	/* 4k breakpoint 3840p */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: source !== "carousel" ? "100%" : "16.95rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
}));

const CustomSkeleton = styled(Skeleton)(({ theme }) => ({
	borderRadius: "10px 10px 0px 0px",
	width: "100%",
	/* XS breakpoint - 280.05 */
	[theme.breakpoints.up("xs")]: {
		height: "8rem",
	},
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		height: "5rem",
	},
	/* galaxy S8+ - 360 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		height: "5.5rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		height: "5.75rem",
	},
	/* iphone XR - 414 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		height: "6rem",
	},
	/* iphone 14 pro max - 430 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		height: "6.25rem",
	},
	/* 457 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 176)]: {
		height: "6.75rem",
	},
	/* 485 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 204)]: {
		height: "7.25rem",
	},
	/* 512 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 231)]: {
		height: "7.5rem",
	},
	/* 540 */
	[theme.breakpoints.up("xsPlus")]: {
		height: "7.75rem",
	},
	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		height: "5.5rem",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		height: "7.75rem",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		height: "6rem",
	},
	/* 684 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		height: "6.25rem",
	},
	/* ipad Mini - 768 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		height: "7rem",
	},
	/* ipad Air - 820 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		height: "8rem",
	},
	/* 860 */
	[theme.breakpoints.up("sm860")]: {
		height: "8.25rem",
	},
	/* 910 */
	[theme.breakpoints.up("sm910")]: {
		height: "8.5rem",
	},
	/* 960 */
	[theme.breakpoints.up("md")]: {
		height: "8.75rem",
	},
	/* iPadPro - 1024 */
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		height: "9.25rem",
	},
	/* 1107 */
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		height: "9.5rem",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		height: "8rem",
	},
	/* 720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		height: "8.5rem",
	},
	/* 1080p 125% breakpoint */
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		height: "9rem",
	},
	/* 4k breakpoint 3840p */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		height: "9rem",
	},
}));

const PropertyCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	padding: "0.5rem 0.5rem 0.5rem 0.5rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 16px 16px",
	cursor: "pointer",
	/* ipad Mini - 768 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		padding: "0.75rem 0.75rem 0.75rem 0.75rem",
	},
}));

const TitlePriceSkeleton = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	gap: "0.25rem",
	margin: "0.25rem 0rem 0.5rem 0rem",
}));

const InfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	margin: "0.45rem 0rem 0rem 0rem",
	width: "100%",
	cursor: "pointer",
	gap: "0.5rem",
	/* ipad Mini - 768 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		gap: "0.65rem",
	},
	/* 860 */
	[theme.breakpoints.up("sm860")]: {
		gap: "0.75rem",
	},
	/* 910 */
	[theme.breakpoints.up("sm910")]: {
		gap: "0.85rem",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		gap: "0.75rem",
	},
}));

const PropertyLoadingCardMini = ({ source, isEditor }: { source?: string; isEditor?: boolean | undefined }) => {
	return (
		<PropertyCardContainer source={source ? source : ""}>
			<PropertyCard
				source={source ? source : ""}
				isEditor={isEditor !== undefined ? isEditor : false}
			>
				{/* Image */}

				<CustomSkeleton
					animation="wave"
					variant="rectangular"
				/>

				<PropertyCardInfoContainer>
					<TitlePriceSkeleton>
						<Skeleton
							animation="wave"
							variant="rectangular"
							width="30%"
							height="0.75rem"
							style={{ borderRadius: "0.25rem" }}
						/>
						<Skeleton
							animation="wave"
							variant="rectangular"
							width="80%"
							height="0.75rem"
							style={{ borderRadius: "0.25rem" }}
						/>
					</TitlePriceSkeleton>

					<InfoContainer>
						<Skeleton
							animation="wave"
							variant="rectangular"
							width="80%"
							height="0.65rem"
							style={{ borderRadius: "0.25rem" }}
						/>

						<Skeleton
							animation="wave"
							variant="rectangular"
							width="80%"
							height="0.65rem"
							style={{ borderRadius: "0.25rem" }}
						/>

						<Skeleton
							animation="wave"
							variant="rectangular"
							width="80%"
							height="0.65rem"
							style={{ borderRadius: "0.25rem" }}
						/>

						{source === "business_profile_listings" && isEditor ? (
							<Skeleton
								animation="wave"
								variant="rectangular"
								width="80%"
								height="0.75rem"
								style={{ borderRadius: "0.25rem" }}
							/>
						) : null}
					</InfoContainer>
				</PropertyCardInfoContainer>
			</PropertyCard>
		</PropertyCardContainer>
	);
};

export default PropertyLoadingCardMini;
