/* Common Imports */

import React from "react";
import PropertyCard from "./PropertyCardGridMiniTwo/PropertyCardGridMiniTwo";

/* Component Imports */

import { ObjectId } from "mongoose";
import StateInterface from "../../../redux-magic/state-interface";
import PropertyLoadingCardMini from "../skeletonLoadingCard/PropertyLoadingCardMiniTwo";

/* Styled Components */

const PropertyCardGridView = ({
	PropertyId,
	dispatch,
	session,
	profile_context,
	isEditor,
	source,
	id,
	search_log_id,
	bottom_beegru_points,
	boost,
	// filter_hash,
}: {
	PropertyId: any;
	dispatch: any;
	session: any;
	profile_context: any;
	isEditor?: boolean | undefined;
	source?: string;
	id?: string;
	search_log_id?: string | ObjectId;
	bottom_beegru_points?: StateInterface["bottom_subscribe_buy_beegru_points"];
	boost?: StateInterface["boost"];
	// filter_hash?: string;
}) => {
	const [propertyData, setPropertyData] = React.useState<any>({});

	const [loading, setLoading] = React.useState(true);

	const [boostPropertySlug, setBoostPropertySlug] = React.useState<string>("");

	const boost_active: boolean = boost?.active || false;

	const boost_property_slug = boost?.property_id || null;

	React.useEffect(() => {
		if (PropertyId) {
			fetch(`${process.env.PRODUCTION_API_URL}properties/cards/${PropertyId}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setPropertyData(data.property_data);
					setLoading(false);
				});
			// we can use promise all to fetch both the data at the same time
			// fetch(`${process.env.PRODUCTION_API_URL}properties/cards/property-stats?_id=${PropertyId}`, {
			// 				method: "POST",
			// 				headers: {
			// 					"Content-Type": "text/plain",
			// 				},
			// 			})
			// 				.then((res) => res.json())
			// 				.then((data) => {
			// 					console.log(data);
			// 				});
		}
	}, [PropertyId]);

	React.useEffect(() => {
		if (boost_property_slug === propertyData.slug) {
			setBoostPropertySlug(boost_property_slug as string);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [boost_property_slug]);

	React.useEffect(() => {
		if (boostPropertySlug === propertyData.slug) {
			fetch(`${process.env.PRODUCTION_API_URL}properties/cards/${PropertyId}`, {
				method: "POST",
				headers: {
					"Content-Type": "text/plain",
				},
			})
				.then((res) => res.json())
				.then((data) => {
					setPropertyData(data.property_data);
					setLoading(false);
				});
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps*/
	}, [boost_active]);

	if (loading) {
		return (
			<PropertyLoadingCardMini
				source={source}
				isEditor={isEditor}
			/>
		);
	}

	return (
		<React.Fragment>
			<PropertyCard
				id={id}
				profile_context={profile_context}
				session={session}
				dispatch={dispatch}
				loading={loading}
				_id={propertyData._id}
				slug={propertyData.slug}
				transaction_type={propertyData.transaction_type}
				property_type={propertyData.property_type}
				title={propertyData.title}
				images={propertyData.images}
				property_status={propertyData.availability}
				possession_date={propertyData.possession_date}
				location={propertyData.location ? propertyData.location.title : ""}
				developer={propertyData.builder ? propertyData.builder.name : ""}
				price={propertyData.price}
				price_per_unit={propertyData.price_per_unit}
				area={propertyData.area}
				bhk={propertyData.bhk}
				floors={propertyData.floors}
				no_of_seats={propertyData.no_of_seats}
				saved_content={propertyData.saved_content}
				boosting={propertyData.boost_information}
				created_by={propertyData.created_by}
				show_contact_details={propertyData.show_contact_details}
				source={source}
				published_status={propertyData.status}
				isEditor={isEditor}
				bottom_beegru_points={bottom_beegru_points}
				boost={boost}
				search_log_id={search_log_id as string}
				// filter_hash={filter_hash}
			/>
		</React.Fragment>
	);
};

export default PropertyCardGridView;
