/* Common Imports */

import React from "react";
import { fontSize, styled } from "@mui/system";

/* Redux Imports */

import { Price } from "../../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Typography, Skeleton } from "@mui/material";
import { CurrencyRupee } from "@mui/icons-material";

/* Library Imports */

import PreparePriceString from "../../../../../lib/PreparePriceString";

/* Styled Components */

const Heading = styled(Typography)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	fontWeight: 500,
	fontSize: "0.875rem",
	lineHeight: "0.875rem",
	letterSpacing: "0rem",
	margin: "0rem 0rem 0rem 0rem",
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		fontSize: "0.7rem",
		lineHeight: "0.7rem",
	},
	/* galaxy S8+ - 360 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		fontSize: "0.775rem",
		lineHeight: "0.775rem",
	},
	/* iphone se - 375 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		fontSize: "0.825rem",
		lineHeight: "0.825rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		fontSize: "0.875rem",
		lineHeight: "0.875rem",
	},
	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		fontSize: "0.75rem",
		lineHeight: "0.75rem",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
		lineHeight: "0.875rem",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		fontSize: "0.775rem",
		lineHeight: "0.775rem",
	},
	/* 684 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		fontSize: "0.825rem",
		lineHeight: "0.825rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		fontSize: "0.875rem",
		lineHeight: "0.875rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.95rem",
		lineHeight: "0.95rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		fontSize: "1.125rem",
		lineHeight: "1.125rem",
	},
	/* 1080p breakpoint */
	[theme.breakpoints.up("xl")]: {
		fontSize: "1.125rem",
		lineHeight: "1.125rem",
	},
}));

const RupeeIcon = styled(CurrencyRupee)(({ theme }) => ({
	margin: "0rem 0rem 0rem 0rem",
	fontSize: "0.875rem",
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		fontSize: "0.7rem",
	},
	/* galaxy S8+ - 360 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		fontSize: "0.775rem",
	},
	/* iphone se - 375 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		fontSize: "0.825rem",
	},
	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		fontSize: "0.75rem",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		fontSize: "0.7rem",
	},
	/* 684 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		fontSize: "0.825rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		fontSize: "0.875rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.95rem",
	},
	/*860*/
	[theme.breakpoints.up("sm860")]: {
		fontSize: "0.95rem",
		margin: "0.1rem 0rem 0rem 0rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		fontSize: "1.125rem",
		margin: "0rem 0rem 0rem 0rem",
	},
	/* 1080p breakpoint */
	[theme.breakpoints.up("xl")]: {
		fontSize: "1.125rem",
	},
}));

const GridCardPrice = ({ price, loading }: { price: Price; loading: boolean }) => {
	if (loading) {
		return (
			<React.Fragment>
				<Skeleton
					animation="wave"
					variant="text"
					width="75%"
					height="auto"
					style={{ marginBottom: "0.5rem" }}
				/>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Heading>
				<RupeeIcon />
				{/* 86.25Cr - 86.25Cr */}
				{price !== undefined
					? price
						? price.min === price.max
							? PreparePriceString(price.max)
							: PreparePriceString(price.min) + " - " + PreparePriceString(price.max)
						: "Price not available"
					: ""}
			</Heading>
		</React.Fragment>
	);
};

export default GridCardPrice;
