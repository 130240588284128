/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";
import StateInterface from "../../../../redux-magic/state-interface";
import { Area, BHK, Price, PricePerUnit } from "../../../../redux-magic/sub-interfaces/sub-interfaces";

/* Component Imports */

import { Chip } from "@mui/material";
import PropertyCardConfigAndContact from "./sub-components/PropertyCardConfig";
import PropertyCardCTA from "./sub-components/PropertyCardCTA";
import PropertyCardImage from "./sub-components/PropertyCardImage";
import PropertyCardLocation from "./sub-components/PropertyCardLocation";
import PropertyCardPrice from "./sub-components/PropertyCardPrice";
import PropertyCardTitle from "./sub-components/PropertyCardTitle";
import PropertyCardType from "./sub-components/PropertyCardType";

/* Library Function Imports */

import { useRouter } from "next/router";
import PrepareConfigString from "../../../../lib/PrepareConfigString";
import { setLoadingThunk } from "../../../../redux-magic/store";

/* Styled Components */

const PropertyLink = styled("a", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	textDecoration: "none",
	width: source === "carousel" ? "auto" : "100%",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:hover": {
		textDecoration: "none",
	},
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source !== "carousel" ? "48%" : "auto",
	},
	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		width: source !== "carousel" ? "48%" : "auto",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: source !== "carousel" ? "31.25%" : "auto",
	},
	/* 960 */
	[theme.breakpoints.up("md")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		width: source !== "carousel" ? "31.75%" : "auto",
	},
	/* 720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: source !== "carousel" ? "32%" : "auto",
	},
	/* 2K breakpoint  2560 ~~4 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: source !== "carousel" ? "23.75%" : "auto",
	},
}));

const PropertyCardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source" && prop !== "isEditor",
})<{ source: string; isEditor: boolean }>(({ theme, source, isEditor }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "10px",
	objectFit: "contain",
	width: "100%",
	// height: "29.65rem",
	scrollSnapAlign: "start",
	flexShrink: 1,
	flexGrow: 1,

	/* XXS breakpoint - <280 */
	[theme.breakpoints.up("xxs")]: {
		// width: "6.65rem",
		// width: "100%",
		// height: source === "business_profile_listings" && isEditor ? "11.75rem" : "9.5rem",
	},
	/* XS breakpoint - 280.05 */
	[theme.breakpoints.up("xs")]: {
		// width: "16rem",
		// height: source === "business_profile_listings" && isEditor ? "18rem" : "15.5rem",
	},
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		width: source !== "carousel" ? "100%" : "9rem",
		// height: source === "business_profile_listings" && isEditor ? "14.25rem" : "12rem",
	},
	/* galaxy S8+ - 360 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		width: source !== "carousel" ? "100%" : "10.125rem",
		// height: source === "business_profile_listings" && isEditor ? "15.25rem" : "13rem",
	},
	/* iphone se - 375 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 94)]: {
		width: source !== "carousel" ? "100%" : "10.5rem",
		// height: source === "business_profile_listings" && isEditor ? "15.25rem" : "13rem",
	},
	/* iphone 12 pro and pixel - 390 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 108.95)]: {
		width: source !== "carousel" ? "100%" : "11rem",
		// height: source === "business_profile_listings" && isEditor ? "15.5rem" : "13.25rem",
	},
	/* iphone XR - 414 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		width: source !== "carousel" ? "100%" : "11.65rem",
		// height: source === "business_profile_listings" && isEditor ? "15.75rem" : "14rem",
	},
	/* iphone 14 pro max - 430 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 149)]: {
		width: source !== "carousel" ? "100%" : "12.1rem",
		// height: source === "business_profile_listings" && isEditor ? "16.25rem" : "14rem",
	},
	/* 457 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 176)]: {
		width: source !== "carousel" ? "100%" : "13rem",
		// height: source === "business_profile_listings" && isEditor ? "16.75rem" : "14.5rem",
	},
	/* 485 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 204)]: {
		width: source !== "carousel" ? "100%" : "13.75rem",
		// height: source === "business_profile_listings" && isEditor ? "17.25rem" : "15rem",
	},
	/* 512 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 231)]: {
		width: source !== "carousel" ? "100%" : "14.5rem",
		// height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/* 540 */
	[theme.breakpoints.up("xsPlus")]: {
		width: source !== "carousel" ? "100%" : "14.75rem",
		// height: source === "business_profile_listings" && isEditor ? "18rem" : "15.75rem",
	},
	/* 570  ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.xsPlus + 30)]: {
		width: source !== "carousel" ? "100%" : "10.125rem",
		// height: source === "business_profile_listings" && isEditor ? "15rem" : "13rem",
	},
	/* 600  ~~2 cards start~~ */
	[theme.breakpoints.up("sm")]: {
		width: source !== "carousel" ? "100%" : "15.25rem",
		// height: source === "business_profile_listings" && isEditor ? "18rem" : "15.75rem",
	},
	/* 642 ~~3 cards start~~ */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 42)]: {
		width: source !== "carousel" ? "100%" : "10.65rem",
		// height: source === "business_profile_listings" && isEditor ? "15.75rem" : "13.5rem",
	},
	/* 684 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 84)]: {
		width: source !== "carousel" ? "100%" : "11.5rem",
		// height: source === "business_profile_listings" && isEditor ? "16rem" : "13.75rem",
	},
	/* 726 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 126)]: {
		width: source !== "carousel" ? "100%" : "12.35rem",
		// height: source === "business_profile_listings" && isEditor ? "16.25rem" : "14rem",
	},
	/* ipad Mini - 768 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		width: source !== "carousel" ? "100%" : "13.25rem",
		// height: source === "business_profile_listings" && isEditor ? "17.75rem" : "15.5rem",
	},
	/* ipad Air - 820 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		width: source !== "carousel" ? "100%" : "14.25rem",
		// height: source === "business_profile_listings" && isEditor ? "19rem" : "16.75rem",
	},
	/* 860 */
	[theme.breakpoints.up("sm860")]: {
		width: source !== "carousel" ? "100%" : "15.25rem",
		// height: source === "business_profile_listings" && isEditor ? "20.25rem" : "18rem",
	},
	/* 910 */
	[theme.breakpoints.up("sm910")]: {
		width: source !== "carousel" ? "100%" : "16.25rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
	/* 960 */
	[theme.breakpoints.up("md")]: {
		width: source !== "carousel" ? "100%" : "16.5rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
	/* iPadPro - 1024 */
	[theme.breakpoints.up(theme.breakpoints.values.md + 64)]: {
		width: source !== "carousel" ? "100%" : "17.75rem",
		// height: source === "business_profile_listings" && isEditor ? "21.75rem" : "19.5rem",
	},
	/* 1107 */
	[theme.breakpoints.up(theme.breakpoints.values.md + 147)]: {
		width: source !== "carousel" ? "100%" : "19.5rem",
		// height: source === "business_profile_listings" && isEditor ? "22.25rem" : "20rem",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		width: source !== "carousel" ? "100%" : "15.65rem",
		// height: source === "business_profile_listings" && isEditor ? "20.25rem" : "18rem",
	},
	/* 720p breakpoint */
	[theme.breakpoints.up("lg")]: {
		width: source !== "carousel" ? "100%" : "16.95rem",
		// height: source === "business_profile_listings" && isEditor ? "20.5rem" : "18.25rem",
	},
	/* 1080p 125% breakpoint */
	[theme.breakpoints.up(theme.breakpoints.values.lg + 150)]: {
		width: source !== "carousel" ? "100%" : "17.95rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
	/* 1080p breakpoint */
	[theme.breakpoints.up("xl")]: {
		width: source !== "carousel" ? "100%" : "17.45rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
	/* 2K breakpoint  2560p */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
		width: source !== "carousel" ? "100%" : "15.45rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
	/* 4k breakpoint 3840p */
	[theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
		width: source !== "carousel" ? "100%" : "16.95rem",
		// height: source === "business_profile_listings" && isEditor ? "21.25rem" : "19rem",
	},
}));

const PropertyCardInfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	padding: "0.5rem 0.5rem 0.5rem 0.5rem",
	margin: "0rem 0rem 0rem 0rem",
	width: "100%",
	height: "100%",
	borderRadius: "0px 0px 16px 16px",
	cursor: "pointer",
	/* ipad Mini - 768 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		padding: "0.75rem 0.75rem 0.75rem 0.75rem",
	},
}));

const SubTextContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	margin: "0.45rem 0rem 0rem 0rem",
	width: "100%",
	cursor: "pointer",
	gap: "0.5rem",
	/* ipad Mini - 768 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		gap: "0.65rem",
	},
	/* 860 */
	[theme.breakpoints.up("sm860")]: {
		gap: "0.75rem",
	},
	/* 910 */
	[theme.breakpoints.up("sm910")]: {
		gap: "0.85rem",
	},
	/* 1190 ~~Desktop Layout Start~~ */
	[theme.breakpoints.up("md1190")]: {
		gap: "0.75rem",
	},
}));

const PriceAndTransactionContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	width: "100%",
}));

const CustomChip = styled(Chip, {
	shouldForwardProp: (prop) => prop !== "transaction_type",
})<{ transaction_type: string }>(({ theme, transaction_type }) => ({
	"& .MuiChip-label": {
		padding: "0rem 0.5rem 0rem 0.5rem",
	},
	height: "1.25rem",
	padding: "0rem",
	fontWeight: 500,
	fontSize: "0.65rem",
	letterSpacing: "0.02857em",
	borderRadius: "6px",
	backgroundColor:
		transaction_type === "buy"
			? "#b7efc5"
			: transaction_type === "rent"
				? "#ffc6ac"
				: transaction_type === "resale"
					? "#caf0f8"
					: "#b7efc5",
	color:
		transaction_type === "buy"
			? "#1e4620"
			: transaction_type === "rent"
				? "#4c2708"
				: transaction_type === "resale"
					? "#212121"
					: "#1e4620",
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		fontSize: "0.625rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		fontSize: "0.7rem",
	},
	/* ipad Air - 820 */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.825rem",
	},
	/* 910 */
	[theme.breakpoints.up("sm910")]: {
		fontSize: "0.875rem",
	},
}));

const PropertyCard = ({
	id,
	profile_context,
	session,
	dispatch,
	loading,
	_id,
	slug,
	transaction_type,
	property_type,
	title,
	images,
	property_status,
	possession_date,
	location,
	published_status,
	developer,
	price,
	price_per_unit,
	area,
	bhk,
	floors,
	no_of_seats,
	saved_content,
	created_by,
	assigned_to,
	isEditor,
	boosting,
	preview,
	show_contact_details,
	source,
	search_log_id,
	bottom_beegru_points,
	boost,
	filter_hash,
}: {
	id?: string;
	profile_context: StateInterface["profile_context"];
	session: any;
	dispatch: Function;
	loading: boolean;
	_id: ObjectId | string;
	slug: string;
	transaction_type: string;
	property_type: Array<string>;
	title: string;
	images: string;
	property_status: Array<string>;
	possession_date: string;
	published_status: string;
	location: string;
	developer: string;
	price: Price;
	price_per_unit: PricePerUnit;
	area: Area;
	bhk: BHK;
	floors: BHK;
	no_of_seats: BHK;
	saved_content: Array<any>;
	created_by?: any;
	assigned_to?: StateInterface["single_business_profile"]["business_profile"]["assigned_to"];
	isEditor?: boolean | undefined;
	boosting: StateInterface["property"]["boost_information"];
	preview?: boolean;
	show_contact_details: boolean;
	source?: string;
	search_log_id?: string | ObjectId;
	bottom_beegru_points?: StateInterface["bottom_subscribe_buy_beegru_points"];
	boost?: StateInterface["boost"];
	filter_hash?: string;
}) => {
	const router = useRouter();

	return (
		<React.Fragment>
			<PropertyLink
				source={source ? source : ""}
				href={`${process.env.PRODUCTION_URL || ""}loading.html?callbackUrl=${encodeURIComponent(
					`${process.env.PRODUCTION_URL || ""}${router.locale}/properties/${slug}${search_log_id ? `?search_log_id=${search_log_id}` : ""}`,
				)}`}
				rel="noopener"
				target="_self"
				referrerPolicy="no-referrer"
				title={title}
				style={{
					direction: router.locale === "ar-AE" ? "rtl" : "ltr",
				}}
				onClick={() => {
					dispatch(
						setLoadingThunk({
							source: "properties",
							loading: true,
						}),
					);
				}}
			>
				<PropertyCardContainer
					source={source ? source : ""}
					isEditor={isEditor !== undefined ? isEditor : false}
					id={id}
				>
					{/* Image */}

					<PropertyCardImage
						_id={_id}
						slug={slug}
						images={images}
						dispatch={dispatch}
						session={session}
						profile_context={profile_context}
						loading={loading}
						saved_content={saved_content}
						created_by={created_by}
						assigned_to={assigned_to}
						isEditor={isEditor}
						boosting={boosting}
						transaction_type={transaction_type}
					/>

					{/* Title */}

					<PropertyCardInfoContainer>
						<PriceAndTransactionContainer>
							{/* Price */}

							<PropertyCardPrice
								loading={loading}
								price={price}
							/>

							{/* Transaction Type Chip */}

							<CustomChip
								label={
									transaction_type && transaction_type === "buy"
										? "Sale"
										: transaction_type.charAt(0).toUpperCase() + transaction_type.slice(1)
								}
								transaction_type={transaction_type}
							/>
						</PriceAndTransactionContainer>

						<PropertyCardTitle
							loading={loading}
							title={title !== "" || undefined ? title : "Unknown Title"}
						/>

						<SubTextContainer>
							{/* Location */}

							<PropertyCardLocation
								loading={loading}
								location={location !== "" || undefined ? location : "Unknown Location"}
							/>

							{/* Property Type */}

							<PropertyCardType
								loading={loading}
								propertyType={property_type ? property_type : []}
							/>

							{/* Config  */}

							<PropertyCardConfigAndContact
								loading={loading}
								config={PrepareConfigString(
									transaction_type,
									property_type,
									bhk,
									price_per_unit,
									floors,
									no_of_seats,
									true,
								)}
								transaction_type={transaction_type ? transaction_type : ""}
								property_type={property_type ? property_type : []}
							/>

							{/* CTA  */}

							{source === "business_profile_listings" && isEditor ? (
								<PropertyCardCTA
									loading={loading}
									// developer={developer !== "" || undefined ? developer : created_by.business_profile_id.title}
									// config={PrepareConfigString(transaction_type, property_type, bhk, price_per_unit, floors, no_of_seats)}
									// card_title={prepareConfigTitle(
									// 	transaction_type as string,
									// 	property_type as Array<string>,
									// 	router.locale ? (router.locale as string) : "en-IN",
									// )}
									published_status={published_status}
									dispatch={dispatch}
									// session={session}
									slug={slug}
									title={title}
									created_by={created_by}
									// show_contact_details={show_contact_details}
									isEditor={isEditor}
									isBoosted={boosting.active}
									// isBoosted={boost ? boost.active : false}
									// bottom_beegru_points={bottom_beegru_points}
									profile_context={profile_context}
								/>
							) : null}
						</SubTextContainer>
					</PropertyCardInfoContainer>
				</PropertyCardContainer>
			</PropertyLink>
		</React.Fragment>
	);
};

export default PropertyCard;
