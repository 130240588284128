/* Common Imports */

import { styled, useTheme } from "@mui/system";

/* Component Imports */

import { Skeleton, Typography } from "@mui/material";

/* Icon Imports */

import {
	BedroomChildOutlined,
	CurrencyRupeeOutlined,
	DomainAddOutlined,
	EventSeatOutlined,
	KingBedOutlined,
	StairsOutlined,
} from "@mui/icons-material";
import prepareConfigTitle from "../../../../../lib/PrepareConfigTitle";

/* Styled Components */

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	height: "100%",
	gap: "0.125rem",
	margin: "0rem 0rem 0rem 0rem",
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		gap: "0.25rem",
	},
	/*ipad Mini - 768*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		gap: "0.25rem",
	},
}));

const Text = styled(Typography)(({ theme }) => ({
	fontSize: "0.775rem",
	lineHeight: "0.775rem",
	color: theme.palette.text.primary,
	/* iphone 5 - 320 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
		fontSize: "0.65rem",
		lineHeight: "0.65rem",
	},
	/* galaxy S8+ - 360 */
	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
		fontSize: "0.75rem",
		lineHeight: "0.75rem",
	},
	/*iphone XR - 414*/
	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
		fontSize: "0.775rem",
		lineHeight: "0.775rem",
	},
	/*ipad Mini */
	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
		fontSize: "0.825rem",
		lineHeight: "0.825rem",
	},
	/* ipad Air*/
	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
		fontSize: "0.875rem",
		lineHeight: "0.875rem",
	},
	/*910*/
	[theme.breakpoints.up("sm910")]: {
		fontSize: "0.95rem",
		lineHeight: "0.95rem",
	},
	/* 1080p breakpoint */
	[theme.breakpoints.up("xl")]: {
		fontSize: "1rem",
		lineHeight: "1rem",
	},
}));

// const CustomIcon = styled(CorporateFareOutlined)(({ theme }) => ({
// 	color: "#b05911",
// 	margin: "0rem 0rem 0rem 0rem",
// 	fontSize: "0.625rem",
// 	/* iphone 5 - 320 */
// 	[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
// 		fontSize: "0.65rem",
// 	},
// 	/* galaxy S8+ - 360 */
// 	[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
// 		fontSize: "0.75rem",
// 	},
// 	/*iphone XR - 414*/
// 	[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
// 		fontSize: "0.775rem",
// 	},
// 	/*ipad Mini */
// 	[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
// 		fontSize: "0.825rem",
// 	},
// 	/* ipad Air*/
// 	[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
// 		fontSize: "0.875rem",
// 	},
// 	/*910*/
// 	[theme.breakpoints.up("sm910")]: {
// 		fontSize: "0.95rem",
// 	},
// 	/* 1080p breakpoint */
// 	[theme.breakpoints.up("xl")]: {
// 		fontSize: "1rem",
// 	},
// }));

const PropertyCardConfig = ({
	loading,
	config,
	transaction_type,
	property_type,
}: {
	loading: boolean;
	config: string | null | undefined;
	transaction_type: string;
	property_type: string[];
}) => {
	const theme = useTheme();

	if (loading) {
		return (
			<Skeleton
				animation="wave"
				variant="text"
				width="75%"
				height="auto"
				style={{ marginBottom: "0.5rem" }}
			/>
		);
	}

	return (
		<Container>
			{prepareConfigTitle(transaction_type, property_type) === "Price/sqft" ? (
				<CurrencyRupeeOutlined
					sx={{
						color: "#b05911",
						margin: "0rem 0rem 0rem 0rem",
						fontSize: "0.775rem",
						/* iphone 5 - 320 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
							fontSize: "0.65rem",
						},
						/* galaxy S8+ - 360 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
							fontSize: "0.75rem",
						},
						/*iphone XR - 414*/
						[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
							fontSize: "0.775rem",
						},
						/*ipad Mini */
						[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
							fontSize: "0.825rem",
						},
						/* ipad Air*/
						[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
							fontSize: "0.875rem",
						},
						/*910*/
						[theme.breakpoints.up("sm910")]: {
							fontSize: "0.95rem",
						},
						/* 1080p breakpoint */
						[theme.breakpoints.up("xl")]: {
							fontSize: "1rem",
						},
					}}
				/>
			) : prepareConfigTitle(transaction_type, property_type) === "Bedrooms" ? (
				<KingBedOutlined
					sx={{
						color: "#b05911",
						margin: "0rem 0rem 0rem 0rem",
						fontSize: "0.775rem",
						/* iphone 5 - 320 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
							fontSize: "0.65rem",
						},
						/* galaxy S8+ - 360 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
							fontSize: "0.75rem",
						},
						/*iphone XR - 414*/
						[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
							fontSize: "0.775rem",
						},
						/*ipad Mini */
						[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
							fontSize: "0.825rem",
						},
						/* ipad Air*/
						[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
							fontSize: "0.875rem",
						},
						/*910*/
						[theme.breakpoints.up("sm910")]: {
							fontSize: "0.95rem",
						},
						/* 1080p breakpoint */
						[theme.breakpoints.up("xl")]: {
							fontSize: "1rem",
						},
					}}
				/>
			) : prepareConfigTitle(transaction_type, property_type) === "Rooms" ? (
				<BedroomChildOutlined
					sx={{
						color: "#b05911",
						margin: "0rem 0rem 0rem 0rem",
						fontSize: "0.775rem",
						/* iphone 5 - 320 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
							fontSize: "0.65rem",
						},
						/* galaxy S8+ - 360 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
							fontSize: "0.75rem",
						},
						/*iphone XR - 414*/
						[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
							fontSize: "0.775rem",
						},
						/*ipad Mini */
						[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
							fontSize: "0.825rem",
						},
						/* ipad Air*/
						[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
							fontSize: "0.875rem",
						},
						/*910*/
						[theme.breakpoints.up("sm910")]: {
							fontSize: "0.95rem",
						},
						/* 1080p breakpoint */
						[theme.breakpoints.up("xl")]: {
							fontSize: "1rem",
						},
					}}
				/>
			) : prepareConfigTitle(transaction_type, property_type) === "No of floors" ? (
				<StairsOutlined
					sx={{
						color: "#b05911",
						margin: "0rem 0rem 0rem 0rem",
						fontSize: "0.775rem",
						/* iphone 5 - 320 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
							fontSize: "0.65rem",
						},
						/* galaxy S8+ - 360 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
							fontSize: "0.75rem",
						},
						/*iphone XR - 414*/
						[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
							fontSize: "0.775rem",
						},
						/*ipad Mini */
						[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
							fontSize: "0.825rem",
						},
						/* ipad Air*/
						[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
							fontSize: "0.875rem",
						},
						/*910*/
						[theme.breakpoints.up("sm910")]: {
							fontSize: "0.95rem",
						},
						/* 1080p breakpoint */
						[theme.breakpoints.up("xl")]: {
							fontSize: "1rem",
						},
					}}
				/>
			) : prepareConfigTitle(transaction_type, property_type) === "No of seats" ? (
				<EventSeatOutlined
					sx={{
						color: "#b05911",
						margin: "0rem 0rem 0rem 0rem",
						fontSize: "0.775rem",
						/* iphone 5 - 320 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
							fontSize: "0.65rem",
						},
						/* galaxy S8+ - 360 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
							fontSize: "0.75rem",
						},
						/*iphone XR - 414*/
						[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
							fontSize: "0.775rem",
						},
						/*ipad Mini */
						[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
							fontSize: "0.825rem",
						},
						/* ipad Air*/
						[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
							fontSize: "0.875rem",
						},
						/*910*/
						[theme.breakpoints.up("sm910")]: {
							fontSize: "0.95rem",
						},
						/* 1080p breakpoint */
						[theme.breakpoints.up("xl")]: {
							fontSize: "1rem",
						},
					}}
				/>
			) : (
				<DomainAddOutlined
					sx={{
						color: "#b05911",
						margin: "0rem 0rem 0rem 0rem",
						fontSize: "0.775rem",
						/* iphone 5 - 320 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 39)]: {
							fontSize: "0.65rem",
						},
						/* galaxy S8+ - 360 */
						[theme.breakpoints.up(theme.breakpoints.values.xs + 78)]: {
							fontSize: "0.75rem",
						},
						/*iphone XR - 414*/
						[theme.breakpoints.up(theme.breakpoints.values.xs + 130)]: {
							fontSize: "0.775rem",
						},
						/*ipad Mini */
						[theme.breakpoints.up(theme.breakpoints.values.sm + 167)]: {
							fontSize: "0.825rem",
						},
						/* ipad Air*/
						[theme.breakpoints.up(theme.breakpoints.values.sm + 219)]: {
							fontSize: "0.875rem",
						},
						/*910*/
						[theme.breakpoints.up("sm910")]: {
							fontSize: "0.95rem",
						},
						/* 1080p breakpoint */
						[theme.breakpoints.up("xl")]: {
							fontSize: "1rem",
						},
					}}
				/>
			)}

			<Text variant="body1">{config}</Text>
		</Container>
	);
};

export default PropertyCardConfig;
